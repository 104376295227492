import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { useUser } from './store';

const pageTitleMessages = defineMessages({
	users: {
		id: 'pageTitle.users',
		defaultMessage: 'Users',
		description: 'Page title for users page',
	},
	'access-codes': {
		id: 'pageTitle.access-codes',
		defaultMessage: 'Access Codes',
		description: 'Page title for access codes page',
	},
	home: {
		id: 'pageTitle.home',
		defaultMessage: 'Welcome, {firstName}',
		description: 'Page title for home page',
	},
	resources: {
		id: 'pageTitle.resources',
		defaultMessage: 'Resources',
		description: 'Page title for resources page',
	},
	plan: {
		id: 'pageTitle.plan',
		defaultMessage: 'Plan & Payment',
		description: 'Page title for plan page',
	},
	getsetup: {
		id: 'pageTitle.getSetup',
		defaultMessage: 'Get Set Up',
		description: 'Page title for get set up page',
	},
	account: {
		id: 'pageTitle.account',
		defaultMessage: 'Account Details',
		description: 'Page title for account page',
	},
	reporting: {
		id: 'pageTitle.reporting',
		defaultMessage: 'Reporting',
		description: ' Page title for reporting page',
	},
});
export function usePageTitle(): string {
	const { user } = useUser();
	const firstName = user.given_name ?? 'Admin';
	const { pathname } = useLocation();
	const { formatMessage } = useIntl();
	const { partnerId } = useParams() as { partnerId?: string };
	const pageName = pathname.replace('/', '').replace(`${partnerId}/`, '') as keyof typeof pageTitleMessages;
	const pageTitle =
		pageName in pageTitleMessages ? formatMessage(pageTitleMessages[pageName], { firstName }) : '';
	return pageTitle;
}

const breadcrumbMessages = defineMessages({
	home: {
		id: 'breadcrumb.home',
		defaultMessage: 'Home',
		description: 'Breadcrumb for home page',
	},
});
export function useBreadcrumbPageTitle(): string {
	const { pathname } = useLocation();
	const { formatMessage } = useIntl();
	const { partnerId } = useParams() as { partnerId?: string };
	const pageName = pathname.replace('/', '').replace(`${partnerId}/`, '');

	// breadcrumb messages override the page title messages for breadcrumbs
	if (pageName in breadcrumbMessages) {
		return formatMessage(breadcrumbMessages[pageName as keyof typeof breadcrumbMessages]);
	}

	const pageTitle =
		pageName in pageTitleMessages
			? formatMessage(pageTitleMessages[pageName as keyof typeof pageTitleMessages])
			: '';
	return pageTitle;
}
